import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../../TC.pdf" title="Terms of use"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Privacy policy"/>;
// const footerDesc = "";

const Education = () => {
  const appState = useSelector(state => state.appReducer);
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies(['consent']);

  const launchFormHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    if (appState.amplitude) {
      appState.amplitude.getInstance().logEvent('ClickPreSignupWorkshop')
    }

    const modal = {
      display: true,
      content: <LaunchForm lang={appState.lang} title="Free register for access to our webminars focusing on SME's 💡🚀!" subtitle="⏳ Few places available." amplitude={appState.amplitude} amplitudeClickEvent="WorkshopSignUpCreated"/>
    };

    dispatch(ACTIONS.showModal(modal))
  };

  const termsUseHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
  };

  const privacyPolicyHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
  };

  const workshopsHandler = (url) => {
    window.location.href = url;
  };

  const cookieHandler = () => {
    setCookie("consent", "dismiss", { path: '/' });
  };

  const closeModal = () => dispatch(ACTIONS.hideModal());

  return (
    <Layout lang={appState.lang} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
      <SEO lang={appState.lang} page="education" title="Education about Business, Entrepreneurship and Innovation" description="From entrepreneur to entrepreneur, let's share knowledge and methodologies to strengthen millions of SMEs."/>
      {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
      {!cookies.consent &&
      <OptOut text="This websites uses cookies to ensure that you get the best experience on our website." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="See Privacy Policy" cookieAction={cookieHandler}/>
      }
      <MainSection title="Education about Business, Entrepreneurship and Innovation" position="left" image="hero-education.jpg" actionLabel="Register to our webinars" action={launchFormHandler}>
        <AppBar pathname={appState.path}/>
      </MainSection>
      <PageSection maxColumns="3" title="Let's learn together">
        <Card
          date="2019-09-27"
          title="How to obtain your first bank account for your business?"
          description="<p><strong>Do you have a business? SMEs, Startups or Freelancers, in this guide we explain the easiest way to open your bank account, completely online, without pinching a single bank.</strong></p>"
          action={() => workshopsHandler("https://evvafinanzas.com/stories/GuiaCuentaNegocio/")}
          actionLabel="Get access"
          amplitudeClickEvent="ClickHowToGetAccountGuide"
          amplitude={appState.amplitude}
          padding
          shadow
        />
        <Card
          date="2019-06-26"
          title="Digital Marketing workshop"
          description="<p><strong>Our first webinar focusing on Digital Marketing for Small Businesses, presented by Alex Aradvin our digital marketing expert. Video available in our Facebook Group, watch it now.</strong></p>"
          action={() => workshopsHandler("https://www.facebook.com/groups/2348327585490643/?source_id=498362467269198")}
          actionLabel="Get access"
          amplitudeClickEvent="ClickMKTWorkshop"
          amplitude={appState.amplitude}
          padding
          shadow
        />
        <Card
          date="Coming soon"
          title="Fiancial Revolution workshop"
          description="<p><strong>Discover the rules of the money game and create a plan to achieve your financial independence. 21-day personal finance challenge starting this 1st. of March<strong></p>"
          action={() => workshopsHandler("https://evvafinanzas.com/revolucion-financiera/")}
          actionLabel="Get access"
          amplitudeClickEvent="ClickFinWorkshop"
          amplitude={appState.amplitude}
          padding
          shadow
        />
      </PageSection>
      <SecondarySection
        title="Together we grow"
        shortDescription="From Entrepreneurs to entrepreneurs, we are people like you who understand your needs. Our ultimate goal is to let you focus on what really matters ... Your business."
        position="center"
        image="evva-together.jpg"
        actionLabel="Get Early Access"
        action={launchFormHandler}
        amplitude={appState.amplitude}
        amplitudeClickEvent="ClickPreSignupWorkshop"
      />
    </Layout>
  )
};

export default Education
